/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "ap-southeast-2",
  aws_cognito_identity_pool_id:
    "ap-southeast-2:a9beb97d-9785-43ed-a60f-d68376a47aeb",
  aws_cognito_region: "ap-southeast-2",
  aws_user_pools_id: "ap-southeast-2_mhBva1O0l",
  aws_user_pools_web_client_id: "427t8fj5qp6id1jcrp1t5genvg",
  oauth: {},
  aws_cognito_login_mechanism: [],
  //   aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_content_delivery_bucket: "traileraudit-20210813234750-hostingbucket-dev",
  aws_content_delivery_bucket_region: "ap-southeast-2",
  aws_content_delivery_url: "https://d1i2x90gpdl19b.cloudfront.net",
};

export default awsmobile;
