import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";

import { PhotoType } from "../../TrailerPhoto/types";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setQuestionaireData } from "../../../store/questionaire-slice";
import { clearPhotos } from "../../../store/trailer-photo-slice";
import { setNotes } from "../../../store/notes-slice";

type TaskId = "PRECOMMIT" | "COMMIT" | PhotoType;

type Status = "PRECOMMIT" | "COMMIT" | "UPLOAD" | "SUCCESS" | "ERROR";

interface Task {
  status: "pending" | "commence" | "in_progress" | "success" | "error";
  message?: string;
  result?: any;
}

export interface Context {
  status: Status;
  tasks: {
    [id in TaskId]: Task;
  };
  updateTask(id: TaskId, task: Task): void;
  retry(): void;
}

const defaultTasks: Context["tasks"] = {
  PRECOMMIT: {
    status: "pending",
  },
  DRIVER_SIDE: {
    status: "pending",
  },
  FRONT: {
    status: "pending",
  },
  INTERNAL: {
    status: "pending",
  },
  PASSENGER_SIDE: {
    status: "pending",
  },
  REAR: {
    status: "pending",
  },
  COMMIT: {
    status: "pending",
  },
};
export const Context = React.createContext<Context>({
  status: "PRECOMMIT",
  tasks: defaultTasks,
  updateTask: () => {},
  retry: () => {},
});

type ContextProviderProps = {};

export const uploadTaskIds: PhotoType[] = [
  "DRIVER_SIDE",
  "FRONT",
  "INTERNAL",
  "PASSENGER_SIDE",
  "REAR",
];

export const ContextProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  const dispatch = useAppDispatch();
  const rego = useAppSelector((state) => state?.questionaire?.rego);

  const [status, setStatus] = useState<Status>("PRECOMMIT");

  const [precommitTask, setPrecommitTask] = useState<Task>({
    status: "pending",
  });
  const [driverSideTask, setDriverSideTask] = useState<Task>({
    status: "pending",
  });
  const [frontTask, setFrontTask] = useState<Task>({
    status: "pending",
  });
  const [internalTask, setInternalTask] = useState<Task>({
    status: "pending",
  });
  const [passengerSideTask, setPassengerSideTask] = useState<Task>({
    status: "pending",
  });
  const [rearTask, setRearTask] = useState<Task>({
    status: "pending",
  });
  const [commitTask, setCommitTask] = useState<Task>({
    status: "pending",
  });

  const tasks: Context["tasks"] = {
    PRECOMMIT: precommitTask,
    COMMIT: commitTask,
    DRIVER_SIDE: driverSideTask,
    PASSENGER_SIDE: passengerSideTask,
    INTERNAL: internalTask,
    FRONT: frontTask,
    REAR: rearTask,
  };

  const updateTask: Context["updateTask"] = (id, task) => {
    console.log(`${id}: updating task to status ${task.status}`);
    switch (id) {
      case "PRECOMMIT":
        setPrecommitTask({ ...precommitTask, ...task });
        break;
      case "DRIVER_SIDE":
        setDriverSideTask({ ...driverSideTask, ...task });
        break;
      case "PASSENGER_SIDE":
        setPassengerSideTask({ ...passengerSideTask, ...task });
        break;
      case "INTERNAL":
        setInternalTask({ ...internalTask, ...task });
        break;
      case "FRONT":
        setFrontTask({ ...frontTask, ...task });
        break;
      case "REAR":
        setRearTask({ ...rearTask, ...task });
        break;
      case "COMMIT":
        setCommitTask({ ...commitTask, ...task });
        break;
    }
  };

  const hasFailedUplaods = () =>
    Object.entries(tasks).some(([id, task]) => {
      if (!uploadTaskIds.includes(id as PhotoType)) {
        return false;
      }
      if (task.status === "error") {
        return true;
      }
      return false;
    });

  const handleUploadRetry = () => {
    // flip all in error to pending, clear msg and result
    const failedTasks = Object.entries(tasks).filter(([id, task]) => {
      if (!uploadTaskIds.includes(id as PhotoType)) {
        return false;
      }
      if (task.status === "error") {
        return true;
      }
      return false;
    });
    if (failedTasks.length) {
      failedTasks.forEach(([id]) => {
        updateTask(id as TaskId, {
          message: undefined,
          result: undefined,
          status: "pending",
        });
      });
      setStatus("UPLOAD");
    }
  };

  const retry: Context["retry"] = () => {
    switch (true) {
      case tasks.PRECOMMIT.status === "error":
        updateTask("PRECOMMIT" as TaskId, {
          message: undefined,
          result: undefined,
          status: "pending",
        });
        setStatus("PRECOMMIT");
        break;
      case hasFailedUplaods():
        handleUploadRetry();
        break;
      case tasks.COMMIT.status === "error":
        updateTask("COMMIT" as TaskId, {
          message: undefined,
          result: undefined,
          status: "pending",
        });
        setStatus("COMMIT");
        break;
    }
  };

  const context: Context = {
    status,
    tasks,
    updateTask,
    retry,
  };

  const handlePrecommit = () => {
    const task = tasks["PRECOMMIT"];
    switch (task.status) {
      case "pending":
        updateTask("PRECOMMIT", {
          ...task,
          status: "commence",
        });
        break;
      case "success":
        setStatus("UPLOAD");
        break;
      case "error":
        setStatus("ERROR");
        break;
    }
  };

  const handleUpload = () => {
    for (const taskId of uploadTaskIds) {
      const task = tasks[taskId];
      switch (task.status) {
        case "pending":
          updateTask(taskId, {
            ...task,
            status: "commence",
          });
          break;
      }
    }

    const uploadTasks = Object.entries(tasks).reduce<Task[]>(
      (memo, [id, task]) =>
        uploadTaskIds.includes(id as PhotoType) ? [...memo, task] : memo,
      []
    );

    // transition on overall success once all have succeeded.
    if (uploadTasks.every(({ status }) => status === "success")) {
      setStatus("COMMIT");
      return;
    }

    // transition to overall error once
    // all are resolved as success or error, i.e. at least one upload
    // must have failed to get passed the above check.
    if (
      uploadTasks.every(({ status }) => ["success", "error"].includes(status))
    ) {
      setStatus("ERROR");
      return;
    }
  };

  const handleCommit = () => {
    const task = tasks["COMMIT"];
    switch (task.status) {
      case "pending":
        updateTask("COMMIT", {
          ...task,
          status: "commence",
        });
        break;
      case "success":
        setStatus("SUCCESS");
        break;
      case "error":
        setStatus("ERROR");
        break;
    }
  };

  const handleSuccess = () => {
    dispatch(setQuestionaireData({}));
    dispatch(clearPhotos());
    dispatch(setNotes(""));
    // Sentry.captureMessage(`${rego}: Successfully created submission`);
  };

  const handleError = () => {
    console.log("tasks", JSON.stringify(tasks, null, 2));
    Sentry.captureMessage(`${rego}: Failed to create submission`);
  };

  const taskStatusesSignature = Object.entries(tasks)
    .map(([id, { status }]) => `${id}=${status}`)
    .join(`;`);
  useEffect(() => {
    console.info(`State`, { status, taskStatusesSignature });
    try {
      switch (status) {
        case "PRECOMMIT":
          handlePrecommit();
          break;
        case "UPLOAD":
          handleUpload();
          break;
        case "COMMIT":
          handleCommit();
          break;
        case "SUCCESS":
          handleSuccess();
          break;
        case "ERROR":
          handleError();
          break;
      }
    } catch (error) {
      // raise sentry
      console.log(`Error during submission`, error);
      Sentry.captureException(error);
    }
  }, [status, taskStatusesSignature]);

  return <Context.Provider value={context}>{children}</Context.Provider>;
};
