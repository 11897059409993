import { useMemo } from "react";
import moment from "moment";
import { Form, Space, DatePicker, FormInstance } from "antd";

import Card from "../../components/Card";

type DateQuestionProps = {
  title: string;
  name: string;
  form: FormInstance;
};

const DateQuestion: React.FC<DateQuestionProps> = ({ title, name, form }) => {
  const onChange = (date: moment.Moment | null, _dateStr: string) => {
    form.setFieldsValue({ [name]: date?.format("YYYY-MM-DD") });
  };

  const initialDate = useMemo(() => {
    const obj = form.getFieldsValue([name]);
    const date = obj[name];
    return date ? moment(date) : undefined;
  }, []);

  return (
    <Card>
      <Form.Item label={title} name={name}>
        <Space direction="vertical">
          <DatePicker
            format="DD/MM/YYYY"
            onChange={onChange}
            defaultValue={initialDate}
          />
        </Space>
      </Form.Item>
    </Card>
  );
};

export default DateQuestion;
