import { Button, Progress, Typography } from "antd";
import { useContext } from "react";
import { Link } from "react-router-dom";

import { Context } from "./Context";

import "./View.less";

const { Text } = Typography;

const View: React.FC = () => {
  const ctx = useContext(Context);
  const tasks = ctx.tasks;
  const failedTasks = Object.values(tasks).filter(
    ({ status }) => status === "error"
  );
  const successfulTasks = Object.values(tasks).filter(
    ({ status }) => status === "success"
  );
  const messages = failedTasks.map(({ message }) => message);
  const successfulPercentage = Math.round(
    (successfulTasks.length / Object.keys(tasks).length) * 100
  );

  // paint the progress bar
  // paint suitable messages
  // conditionally paint the retry button
  // paint the success button on success

  let status: "normal" | "exception" | "active" | "success" = "active";
  if (ctx.status === "ERROR") {
    status = "exception";
  }
  if (ctx.status === "SUCCESS") {
    status = "success";
  }

  return (
    <>
      <Progress
        type="circle"
        percent={successfulPercentage}
        className="ms-submission-worker-view"
        status={status}
      />
      {ctx.status === "ERROR" && (
        <>
          <Text className="ms-submission-worker-label">
            Oops, there's been a problem. Please check your internet and retry.
          </Text>
          <Button type="primary" onClick={ctx.retry}>
            Retry
          </Button>
        </>
      )}
      {ctx.status === "SUCCESS" && (
        <Link to="/">
          <Button type="primary">Audit another trailer</Button>
        </Link>
      )}
    </>
  );
};

export default View;
