import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PhotoType } from "../pages/TrailerPhoto/types";

export type TrailerPhoto = {
  contentType: string;
  base64: string;
};
type TrailerPhotoState = {
  [type in PhotoType]: TrailerPhoto;
};

const initialState = {} as TrailerPhotoState;

export const trailerPhotoSlice = createSlice({
  name: "trailerPhoto",
  initialState,
  reducers: {
    addPhoto: (
      state,
      { payload }: PayloadAction<{ type: PhotoType } & TrailerPhoto>
    ) => {
      const { type, ...rest } = payload;
      state[payload.type] = rest;
    },
    clearPhotos: () => {
      return initialState;
    },
  },
});

export const { addPhoto, clearPhotos } = trailerPhotoSlice.actions;

export default trailerPhotoSlice.reducer;
