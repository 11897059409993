import { useEffect, useContext } from "react";
import * as Sentry from "@sentry/react";

import { useAppSelector } from "../../../store";
import { useLazyPrecommitAuditQuery } from "../../../store/api";
import { Context } from "./Context";

const Precommit: React.FC = () => {
  const auditId = useAppSelector((state) => state?.questionaire?.auditId);
  const [
    precommit,
    { data, isSuccess, isError, error, isFetching, isUninitialized },
  ] = useLazyPrecommitAuditQuery();
  const ctx = useContext(Context);
  const task = ctx.tasks["PRECOMMIT"];

  useEffect(() => {
    if (!auditId) {
      console.log(`Audit id not set`);
      return;
    }
    if (task.status !== "commence") {
      console.log(`Will not trigger precommit`, { status: task.status });
      return;
    }

    console.log(`Calling precommit`);
    ctx.updateTask("PRECOMMIT", { status: "in_progress" });
    precommit(auditId);
  }, [auditId, task.status]);

  useEffect(() => {
    if (isUninitialized) {
      console.log(`Precommit has not yet been triggered`);
      return;
    }
    if (isFetching) {
      console.log(`Precommit is fetching`);
      return;
    }
    if (isSuccess) {
      ctx.updateTask("PRECOMMIT", { status: "success", result: data });
      return;
    }
    if (isError) {
      ctx.updateTask("PRECOMMIT", {
        status: "error",
        result: error,
        message: `Precommit has failed`,
      });
      return;
    }

    const message = `Precommit query is in an unknown state`;
    console.log(message, {
      data,
      error,
      isError,
      isFetching,
      isSuccess,
      isUninitialized,
    });
    Sentry.captureMessage(message);
  }, [isUninitialized, isFetching, isSuccess, isError]);

  return null;
};

export default Precommit;
