import { ChangeEventHandler, useState } from "react";
import { useHistory } from "react-router-dom";
import { Typography, Space, Input } from "antd";

import Actions from "../../components/Actions";
import Card from "../../components/Card";
import { useAppDispatch, useAppSelector } from "../../store";
import { setNotes as storeNotes } from "../../store/notes-slice";

const { TextArea } = Input;
const { Text } = Typography;

const Notes: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const rego = useAppSelector(({ questionaire: { rego } }) => rego) ?? "";
  const storedNotes = useAppSelector((state) => state.notes.notes);
  const [localNotes, setLocalNotes] = useState(storedNotes);

  const onNext = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(storeNotes(localNotes));

    history.push(`/submission`);
  };

  const onBack = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(storeNotes(localNotes));
  };

  const onChange: ChangeEventHandler<HTMLTextAreaElement> = async (event) => {
    const text = event.target.value;
    setLocalNotes(text);
  };

  return (
    <>
      <Card title={`Notes for ${rego}`} className="ms-notes">
        <Space direction="vertical">
          <TextArea rows={5} value={localNotes} onChange={onChange} />
          <Text italic>
            * Notes will be emailed to you for reference, once the audit
            submission has completed.
          </Text>
        </Space>
      </Card>
      <Actions onNext={onNext} onBack={onBack} />
    </>
  );
};

export default Notes;
