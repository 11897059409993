import { useEffect, useRef, useState } from "react";
import { Button, Upload } from "antd";
import { CameraOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import Actions from "../../components/Actions";
import Card from "../../components/Card";
import { PhotoType } from "./types";
import { classes } from "../../util/util";
import { addPhoto } from "../../store/trailer-photo-slice";
import { useAppDispatch, useAppSelector } from "../../store";

import "./TrailerPhoto.less";

type TrailerMetadata = {
  [key in PhotoType]: {
    name: string;
  };
};

const trailerMetadata: TrailerMetadata = {
  REAR: {
    name: "rear of trailer",
  },
  INTERNAL: {
    name: "inside the bin",
  },
  DRIVER_SIDE: {
    name: "driver's side of trailer",
  },
  FRONT: {
    name: "front of trailer",
  },
  PASSENGER_SIDE: {
    name: "passenger's side of trailer",
  },
};
const types = Object.keys(trailerMetadata);

type TrailerPhotoProps = {
  type: PhotoType;
};

type TypeMetadata = {
  isError: boolean;
  next?: keyof TrailerMetadata | null;
};
const getTypeMetadata = (type: keyof TrailerMetadata): TypeMetadata => {
  let i = types.indexOf(type);
  if (i < 0) {
    return { isError: true };
  }

  i++;

  const next = i < types.length ? (types[i] as keyof TrailerMetadata) : null;
  return {
    isError: false,
    next,
  };
};

const TrailerPhoto: React.FC<TrailerPhotoProps> = ({ type }) => {
  const history = useHistory();
  const b64Image = useAppSelector((state) => state.trailerPhoto[type]?.base64);
  const previewImgEl = useRef<HTMLImageElement>(null);
  const dispatch = useAppDispatch();
  const [typeMetadata, setTypeMetadata] = useState<TypeMetadata>({
    isError: false,
  });

  const hasPhoto = !!b64Image;
  const { isError, next } = typeMetadata;

  useEffect(() => {
    previewImgEl.current!.src = b64Image;
  }, [hasPhoto, !!previewImgEl.current]);

  useEffect(() => {
    setTypeMetadata(getTypeMetadata(type));
  }, []);

  const metadata = trailerMetadata[type];
  // TODO: route to 404 on no metadata

  const onNext = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (isError) {
      console.log(`Could not find type ${type}`);
      return;
    }

    history.push(next ? `/photo/${next}` : `/notes`);
  };

  const onChange = async ({ file }: { file: File }) => {
    const base64: string = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (err) => reject(err);
    });

    dispatch(addPhoto({ type, contentType: file.type, base64 }));
  };

  const beforeUpload = async (file: any) => {
    return false;
  };

  const sampleCls = classes("sample", hasPhoto ? "hide" : "show");
  const previewCls = classes("preview", hasPhoto ? "show" : "hide");

  const unsupportedUploadProps = { capture: "camera" } as any;

  return (
    <>
      <Card title={`Photo of ${metadata.name}`} className="trailer-photo">
        <img className={sampleCls} src={`/photo-${type}.png`} alt={type} />
        <img
          className={previewCls}
          alt={`${type}-preview`}
          ref={previewImgEl}
        />
        <Upload
          beforeUpload={beforeUpload}
          onChange={onChange}
          listType="picture"
          showUploadList={false}
          accept="image/*;capture=camera"
          {...unsupportedUploadProps}
        >
          <Button
            className="custom-button"
            type={hasPhoto ? "ghost" : "primary"}
            icon={<CameraOutlined />}
          >
            {hasPhoto ? "Retake" : "Take"} photo
          </Button>
          <Button
            className="custom-button"
            type="primary"
            onClick={onNext}
            disabled={!hasPhoto}
          >
            {next ? "Next photo" : "Next"}
          </Button>
        </Upload>
      </Card>
      <Actions hasBack nextDisabled={!hasPhoto} onNext={onNext} />
    </>
  );
};

export default TrailerPhoto;
