import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
// @ts-ignore
import omitDeep from "deepdash/omitDeep";

import questionaireSlice from "./questionaire-slice";
import trailerPhotoSlice from "./trailer-photo-slice";
import notesSlice from "./notes-slice";

import { trailerAuditApi } from "./api";

// TODO: all state should be keyed by rego, so the user can maintain
// multiple audits simultaneously.

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state) => {
    return omitDeep(state, ["base64"]); // don't send base64 trailer images to sentry. Getting 413, payload too big!
  },
  actionTransformer: (action) => {
    return omitDeep(action, ["base64"]); // don't send base64 trailer images to sentry. Getting 413, payload too big!
  },
});

export const store = configureStore({
  reducer: {
    questionaire: questionaireSlice,
    trailerPhoto: trailerPhotoSlice,
    notes: notesSlice,
    [trailerAuditApi.reducerPath]: trailerAuditApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(trailerAuditApi.middleware),
  enhancers: [sentryReduxEnhancer],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
