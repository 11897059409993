import { Button, Form } from "antd";
import { useHistory } from "react-router-dom";
import Card from "../Card";

import "./Actions.less";

// TODO: make next more generic, not a submit button ?  Provide onClick instead?

type ActionsProps = {
  onNext?: (e: any) => any;
  hasBack?: boolean;
  onBack?: (e: any) => any;
  nextDisabled?: boolean;
};

const Actions: React.FC<ActionsProps> = ({
  hasBack,
  onNext,
  nextDisabled = false,
  children,
  onBack: customOnBack,
}) => {
  const history = useHistory();
  const onBack = (e: any) => {
    customOnBack && customOnBack(e);
    history.goBack();
  };

  return (
    <Card>
      <Form.Item className="actions">
        {(hasBack || customOnBack) && (
          <Button type="default" onClick={onBack}>
            Back
          </Button>
        )}
        {children}
        {onNext && (
          <Button
            className="next-button"
            type="primary"
            onClick={onNext}
            disabled={nextDisabled}
          >
            Next
          </Button>
        )}
      </Form.Item>
    </Card>
  );
};

export default Actions;
