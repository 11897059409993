import { Form } from "antd";
import { useHistory, useLocation } from "react-router-dom";

import Actions from "../../components/Actions";
import { useAppDispatch, useAppSelector } from "../../store";
import { setQuestionaireData } from "../../store/questionaire-slice";
import { useGetAuditsQuery } from "../../store/api";

import RegoSelect from "./RegoSelect";
import YesNoQuestion from "./YesNoQuestion";
import { useEffect } from "react";
import DateQuestion from "./DateQuestion";

const Questionaire = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const rego = useAppSelector((state) => state.questionaire.rego);
  const answers = useAppSelector((state) => state.questionaire.answers);
  const dispatch = useAppDispatch();
  const { data: audits, isLoading } = useGetAuditsQuery();

  useEffect(() => {
    const qs = new URLSearchParams(location.search);
    const defaultRego = qs.get("rego");
    if (defaultRego) {
      console.log(`Setting default rego to ${defaultRego}`);
      form.setFields([{ name: "rego", value: defaultRego }]);
    }
  }, [location.search]);

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={{ rego, ...answers }}
      onFinish={(values) => {
        const { rego, ...answers } = values;
        const audit = audits?.find((audit) => audit.rego === rego);
        dispatch(
          setQuestionaireData({ auditId: audit?.auditId, rego, answers })
        );
        history.push("/photo/rear");
      }}
    >
      <RegoSelect
        regos={audits ?? []}
        selectProps={{ disabled: isLoading || !audits?.length }}
      />

      <YesNoQuestion
        title="Handbrake adjusted and brakes working?"
        name="hasCheckedBrakes"
      />
      <YesNoQuestion
        title="Wheel nuts tight and tyre pressure correct?"
        name="hasCheckedTyres"
      />
      <DateQuestion
        title="Date of last wheel bearings service (leave blank if not done)?"
        name="wheelBearingsServicedAt"
        form={form}
      />
      <YesNoQuestion
        title="Lights (brake, indicators &amp; side) working?"
        name="hasCheckedLights"
      />
      <YesNoQuestion
        title="Bin retention on both sides (pins or turnbuckles)?"
        name="hasCheckedBinRetention"
      />
      <YesNoQuestion
        title="Bin lids (inc arms and locking chains) in good condition?"
        name="hasCheckedBinLids"
      />
      <YesNoQuestion
        title="Jockey wheel in good condition?"
        name="hasCheckedJockeyWheel"
      />

      <Actions onNext={form.submit} />
    </Form>
  );
};

export default Questionaire;
