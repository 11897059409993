import { Form, Select, SelectProps } from "antd";

import Card from "../../components/Card";

const { Option } = Select;

export type RegoSelectProps = {
  regos: Array<{ rego: string; auditId: string }>;
  selectProps?: SelectProps<any>;
};

const RegoSelect: React.FC<RegoSelectProps> = ({ regos, selectProps }) => {
  return (
    <Card bordered>
      <Form.Item
        label="Choose trailer rego"
        name="rego"
        rules={[{ required: true, message: "Please select a rego" }]}
      >
        <Select {...selectProps}>
          {regos?.map(({ auditId, rego }) => (
            <Option key={auditId} value={rego}>
              {rego}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Card>
  );
};

export default RegoSelect;
