import { Layout as AntLayout, Col, Row } from "antd";
import { Link } from "react-router-dom";

import "./Layout.less";
const { Header, Content, Footer } = AntLayout;

const Layout: React.FC = ({ children }) => (
  <AntLayout className="layout">
    <Header className="layout-header">
      <Link to="/">
        <img className="logo" src="/ms-logo.png" alt="Mobile skips" />
      </Link>
    </Header>
    <Content>
      <Row justify="center">
        <Col xs={24} lg={16}>
          <div className="site-layout-content">{children}</div>
        </Col>
      </Row>
    </Content>
    <Footer style={{ textAlign: "center" }}>Mobile Skips ©2021</Footer>
  </AntLayout>
);

export default Layout;
