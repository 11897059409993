import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import "./index.less";

import App from "./App";
import Authenticated from "./components/Authenticated";
import Init from "./components/Init";

import reportWebVitals from "./reportWebVitals";
import { store } from "./store";

Sentry.init({
  dsn: "https://62e6e46b6a9c49e2abd824467239e8b8@o958849.ingest.sentry.io/5907395",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  release: process.env.REACT_APP_RELEASE,
  normalizeDepth: 10, // Or however deep you want your state context to be.
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Authenticated>
        <>
          <Init />
          <App />
        </>
      </Authenticated>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
