import * as Sentry from "@sentry/react";

import Worker from "./Worker";

import "./Submission.less";

const Submission: React.FC = () => {
  return (
    <Sentry.ErrorBoundary fallback={<h1>Looks like there was a problem :(</h1>}>
      <div className="submissionWrapper">
        <Worker />
      </div>
    </Sentry.ErrorBoundary>
  );
};

export default Submission;
