import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import "./Authenticated.less";

import awsconfig from "../../aws-exports";

Amplify.configure(awsconfig);

const formFields = {
  signIn: {
    username: {
      placeholder: "Email",
    },
  },
  signUp: {
    username: {
      placeholder: "Email",
    },
  },
};

const Authenticated: React.FC<any> = ({ children }) => {
  return <Authenticator formFields={formFields}>{children}</Authenticator>;
};

export default Authenticated;
