import { createSlice } from "@reduxjs/toolkit";

interface NotesState {
  notes: string;
}

const initialState = { notes: "" } as NotesState;

export const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    setNotes: (_, action) => {
      return { notes: action.payload };
    },
  },
});

export const { setNotes } = notesSlice.actions;

export default notesSlice.reducer;
