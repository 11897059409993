import Commit from "./Commit";
import { ContextProvider, uploadTaskIds } from "./Context";
import Precommit from "./Precommit";
import Upload from "./Upload";
import View from "./View";

const Worker: React.FC = () => {
  const uploads = uploadTaskIds.map((type) => (
    <Upload taskId={type} key={type} />
  ));

  return (
    <ContextProvider>
      <View />
      <Precommit />
      {uploads}
      <Commit />
    </ContextProvider>
  );
};

export default Worker;
