import { useEffect, useContext, useState } from "react";

import { useAppSelector } from "../../../store";
import { Context } from "./Context";
import { PhotoType } from "../../TrailerPhoto/types";
import { PendingCommit } from "../../../store/api/types";
import { dataUrlToBlob } from "../util";
import { TrailerPhoto } from "../../../store/trailer-photo-slice";

interface UploadProps {
  taskId: PhotoType;
}

const requiredContentType = "image/jpeg";

type PhotoUrl = PendingCommit["trailerPhotoUrls"][number];
const putToS3 = async (photoUrl: PhotoUrl, blob: Blob) => {
  const res = await fetch(photoUrl.url, {
    method: "PUT",
    headers: { "Content-Type": requiredContentType },
    body: blob,
  });
  if (!res.ok) {
    throw new Error(`Failed to upload to ${photoUrl.url}`);
  } else {
    console.info(`Successfully uploaded to ${photoUrl.url}`);
  }
};

const convertToBlob = (photo: TrailerPhoto): Blob => {
  const { base64, contentType } = photo;
  const blob = dataUrlToBlob(base64, contentType);
  if (contentType !== requiredContentType) {
    throw new Error(`content type must be jpeg, got ${contentType}`);
  }

  return blob;
};

const uploadPhoto = async (
  { trailerPhotoUrls }: PendingCommit,
  photo: TrailerPhoto,
  type: PhotoType
) => {
  const photoUrl = trailerPhotoUrls.find((photoUrl) => photoUrl.type === type);
  if (!photoUrl) {
    throw new Error(`Could not find a photo of type ${type} to upload`);
  }
  const blob = convertToBlob(photo);

  console.log(`Uploading ${type} image`, photoUrl.url);
  await putToS3(photoUrl, blob);
};

const Upload: React.FC<UploadProps> = ({ taskId }) => {
  const ctx = useContext(Context);
  const task = ctx.tasks[taskId];
  const photo = useAppSelector((state) => state?.trailerPhoto?.[taskId]);

  useEffect(() => {
    console.log(`${taskId}: upload check`, task.status, "fetching");
    if (task.status !== "commence") {
      console.log(`${taskId}: Upload won't commence. status ${task.status}`);
      return;
    }

    const run = async () => {
      try {
        console.log(`${taskId}: Commencing upload`);
        ctx.updateTask(taskId, { status: "in_progress" });

        const precommitTask = ctx.tasks.PRECOMMIT;
        if (!precommitTask.result) {
          throw new Error(`${taskId}: precommit task has no result`);
        }
        const precommitResult = precommitTask.result as PendingCommit;
        await uploadPhoto(precommitResult, photo, taskId);

        ctx.updateTask(taskId, { status: "success" });
      } catch (error) {
        console.log(`${taskId}: Upload failed`, error);
        ctx.updateTask(taskId, {
          status: "error",
          message: `${taskId}: Upload failed`,
          result: error,
        });
      }
    };

    run();
  }, [task.status]);

  return null;
};

export default Upload;
