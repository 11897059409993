import { Card as AntCard } from "antd";
import { CardProps } from "antd/lib/card";
import { classes } from "../../util/util";

import "./Card.less";

type MsCardProps = CardProps;

const Card: React.FC<MsCardProps> = ({ className, ...props }) => (
  <AntCard className={classes("ms-card", className)} {...props} />
);

export default Card;
