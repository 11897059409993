import { useEffect, useContext } from "react";
import * as Sentry from "@sentry/react";

import { useAppSelector } from "../../../store";
import { useCommitAuditMutation } from "../../../store/api";
import { Context } from "./Context";

const Commit: React.FC = () => {
  const state = useAppSelector((state) => state);
  const questionaire = state.questionaire;
  const notes = state.notes.notes;

  const [
    commit,
    { data, isSuccess, isError, error, isLoading, isUninitialized },
  ] = useCommitAuditMutation();
  const ctx = useContext(Context);
  const task = ctx.tasks["COMMIT"];

  useEffect(() => {
    if (task.status !== "commence") {
      console.log(`Will not trigger commit`, { status: task.status });
      return;
    }

    console.log(`Calling commit`);
    ctx.updateTask("COMMIT", { status: "in_progress" });

    const {
      result: { commitId },
    } = ctx.tasks.PRECOMMIT;
    const { answers, auditId } = questionaire;

    commit({
      commitId,
      auditId,
      answers,
      notes,
    } as any);
  }, [task.status]);

  useEffect(() => {
    if (isUninitialized) {
      console.log(`Commit has not yet been triggered`);
      return;
    }
    if (isLoading) {
      console.log(`Commit is fetching`);
      return;
    }
    if (isSuccess) {
      ctx.updateTask("COMMIT", { status: "success", result: data });
      return;
    }
    if (isError) {
      ctx.updateTask("COMMIT", {
        status: "error",
        result: error,
        message: `Commit has failed`,
      });
      return;
    }

    const message = `Commit query is in an unknown state`;
    console.log(message, {
      data,
      error,
      isError,
      isLoading,
      isSuccess,
      isUninitialized,
    });
    Sentry.captureMessage(message);
  }, [isUninitialized, isLoading, isSuccess, isError]);

  return null;
};

export default Commit;
