import { useEffect } from "react";

const Explosion = () => {
  useEffect(() => {
    throw new Error(`ka-boom!`);
  });
  return null;
};

export default Explosion;
