import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Auth } from "aws-amplify";

import { Audit, AuditCommit, PendingCommit } from "./types";

export const apiUrl = process.env.REACT_APP_TRAILER_AUDIT_API_URL!;

// Define a service using a base URL and expected endpoints
export const trailerAuditApi = createApi({
  tagTypes: ["Audit"],
  reducerPath: "trailerAuditApi",
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl, // using CRA proxy in dev
    prepareHeaders: async (headers, { getState }) => {
      const session = await Auth.currentSession();
      const token = session.getAccessToken()?.getJwtToken();

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAudits: builder.query<Audit[], void>({
      query: () => `/audit`,
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ auditId }) => ({
                type: "Audit" as const,
                auditId,
              })),
              "Audit",
            ]
          : ["Audit"],
    }),
    // default caching of response probably what we want here?
    precommitAudit: builder.query<PendingCommit, string>({
      query: (auditId) => `/audit/${auditId}/precommit`,
    }),
    commitAudit: builder.mutation<void, AuditCommit>({
      query: ({ commitId, answers, ...rest }) => ({
        url: `audit/${commitId}/commit`,
        method: "POST",
        body: { answers: JSON.stringify(answers), ...rest },
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: "Audit", auditId: arg.auditId },
      ],
    }),
  }),
});

export const {
  useGetAuditsQuery,
  useLazyPrecommitAuditQuery,
  useCommitAuditMutation,
} = trailerAuditApi;
