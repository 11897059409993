import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./App.less";
import Explosion from "./pages/Explosion";
import Layout from "./pages/Layout";
import Notes from "./pages/Notes";
import Questionaire from "./pages/Questionaire";
import Submission from "./pages/Submission";
import TrailerPhoto from "./pages/TrailerPhoto";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Layout>
            <Route path="/photo/REAR">
              <TrailerPhoto type="REAR" />
            </Route>
            <Route path="/photo/DRIVER_SIDE">
              <TrailerPhoto type="DRIVER_SIDE" />
            </Route>
            <Route path="/photo/PASSENGER_SIDE">
              <TrailerPhoto type="PASSENGER_SIDE" />
            </Route>
            <Route path="/photo/FRONT">
              <TrailerPhoto type="FRONT" />
            </Route>
            <Route path="/photo/INTERNAL">
              <TrailerPhoto type="INTERNAL" />
            </Route>
            <Route path="/notes">
              <Notes />
            </Route>
            <Route path="/submission">
              <Submission />
            </Route>
            <Route path="/explosion">
              <Explosion />
            </Route>
            <Route exact path="/">
              <Questionaire />
            </Route>
          </Layout>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
