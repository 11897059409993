import { Form, Radio, Space } from "antd";
import Card from "../../components/Card";

type YesNoQuestionProps = {
  title: string;
  name: string;
};

const YesNoQuestion: React.FC<YesNoQuestionProps> = ({ title, name }) => {
  return (
    <Card>
      <Form.Item
        label={title}
        name={name}
        rules={[{ required: true, message: "Please provide an answer" }]}
      >
        <Radio.Group>
          <Space direction="vertical">
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
    </Card>
  );
};

export default YesNoQuestion;
