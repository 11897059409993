import { createSlice } from "@reduxjs/toolkit";

interface QuestionaireState {
  auditId?: string;
  rego?: string;
  answers: any;
}

const initialState = { answers: {} } as QuestionaireState;

export const questionaireSlice = createSlice({
  name: "questionaire",
  initialState,
  reducers: {
    setQuestionaireData: (_, action) => {
      return action.payload;
    },
  },
});

export const { setQuestionaireData } = questionaireSlice.actions;

export default questionaireSlice.reducer;
